import React, { useState } from "react";
import { Form, Table } from "antd";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import {
  usePostMemberDataMutation,
  useDeleteMemberDataMutation,
  usePostFlightDataMutation,
  useDeleteFlightDataMutation,
  useCreateAccommodationMutation,
  useDeleteAccommodationMutation,
  useUpdateAccommodationMutation,
  useUpdateMemberMutation,
  useGetSingleMemberMutation,
  useUpdateArrivalDataMutation,
  useGetArrivalDataByIdMutation,
  useGetSingleFlightMutation,
  useGetSingleAccommodationMutation,
  useUpdateFlightMutation,
  usePostTrafficDataMutation,
  useGetSinglePathwayMutation,
  useDeletePathwayDataMutation,
  useUpdatePathwayMutation,
} from "apis/services/arrival";
import { useSearchParams } from "react-router-dom";
import Image from "components/general/Image";
import Button from "components/general/Button";
import CONFIRM_IMG from "assets/images/tick-square.png";
import CLOSE_IMG from "assets/images/close-square.png";
import getTableCellInputType from "utils/getTableCellInputType";
import EditableCell from "./EditableCell";
import ActionMenuButton from "./ActionMenuButton";
import styles from "./styles.module.scss";
import { FormTableProps, ColumnTypes } from "./types";

export default function TableForm({
  data,
  setData,
  title,
  columns,
  editingKey,
  setEditingKey,
  navigation,
  deleteActionText,
  tourPathMethod,
  isFromPathwayPage,
  fetchData,
  urlId,
  setSelectedPathway,
  setSelectedTrip,
  setIsLoading,
  paginationCount,
  paginationName,
}: FormTableProps) {
  const [form] = Form.useForm();
  const [clickedRowIndex, setClickedRowIndex] = useState<number | null>(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [postMemberData] = usePostMemberDataMutation();
  const [deleteMemberData] = useDeleteMemberDataMutation();
  const [postFlightData] = usePostFlightDataMutation();
  const [deleteFlightData] = useDeleteFlightDataMutation();
  const [createAccommodation] = useCreateAccommodationMutation();
  const [deleteAccommodation] = useDeleteAccommodationMutation();
  const [updateAccommodation] = useUpdateAccommodationMutation();
  const [updateMember] = useUpdateMemberMutation();
  const [getSingleMember] = useGetSingleMemberMutation();
  const [updateArrivalData] = useUpdateArrivalDataMutation();
  const [getArrivalData] = useGetArrivalDataByIdMutation();
  const [getSingleFlight] = useGetSingleFlightMutation();
  const [getSingleAccommodation] = useGetSingleAccommodationMutation();
  const [updateFlight] = useUpdateFlightMutation();
  const [postPathway] = usePostTrafficDataMutation();
  const [getSinglePathway] = useGetSinglePathwayMutation();
  const [deletePathway] = useDeletePathwayDataMutation();
  const [updatePathway] = useUpdatePathwayMutation();

  const [isAddedMember, setIsAddedMember] = useState(true);
  const [isAddedFlight, setIsAddedFlight] = useState(true);
  const [isAddedAccommodation, setIsAddedAccommodation] = useState(true);
  const isEditing = (record: any) => record.key === editingKey;
  const edit = (record: any) => {
    if (title === "pathway") {
      searchParams.set("id", record?.arrival?.id);
      setSearchParams(searchParams);
      if (setSelectedPathway) setSelectedPathway(record.type);

      getSinglePathway(record?.id)
        .unwrap()
        .then((pathwayData: any) => {
          searchParams.set("pathwayTrip", pathwayData?.name?.name);
          setSearchParams(searchParams);
          form.setFieldsValue({
            ...pathwayData,
            driverName: pathwayData.transportation.id,
            dropTo: pathwayData.drop_off,
            flightNum: pathwayData?.flight?.id || "",
            guideInVisits: pathwayData?.guide?.id,
            leaderName: pathwayData.leader.id,
            flight: pathwayData.name.id,
            pathwayType: pathwayData?.type,
            date: dayjs(pathwayData?.date),
            adultNum: pathwayData?.adult,
            childNum: pathwayData?.child,
            time: dayjs(pathwayData?.time, "HH:mm:ss"),
            pickUp: pathwayData?.pick_up?.id,
            // fileNum: 5,
            pathway: pathwayData?.name?.id,
            city: pathwayData?.type === "trip" && pathwayData?.city.id,
          });
        });
    }

    setIsAddedMember(false);
    setIsAddedFlight(false);
    setIsAddedAccommodation(false);
    if (title === "members") {
      getSingleMember(record?.key)
        .unwrap()
        .then((memberData: any) => {
          const { ...value } = memberData;

          form.setFieldsValue({
            ...value,
          });
        });
    }
    if (title === "flights") {
      getSingleFlight(record?.key)
        .unwrap()
        .then((flightData: any) => {
          const {
            member,
            date,
            arrival_time,
            departure_time,
            departure,
            destination,
            ...value
          } = flightData;
          form.setFieldsValue({
            date: dayjs(date),
            arrival_time: dayjs(arrival_time, "HH:mm:ss"),
            departure_time: dayjs(departure_time, "HH:mm:ss"),
            flightFrom: departure.id,
            flightTo: destination.id,
            ...value,
            member: member.id,
          });
        });
    }

    if (title === "accommodation") {
      getSingleAccommodation(record?.id)
        .unwrap()
        .then((accommodationData: any) => {
          const {
            member,
            check_in,
            check_out,
            city,
            currency,
            hotel_cruise,
            by,
            type,
            category,
            guide,
            due_date,
            ...value
          } = accommodationData;

          form.setFieldsValue({
            ...value,
            accGuestName: member.id,
            accCity: city.id,
            currency: currency.id,
            accType: type,
            hotelName: hotel_cruise.id,
            check_in: dayjs(check_in),
            check_out: dayjs(check_out),
            due_date: dayjs(due_date),
            accByWho: by,
            category,
            hotel_cruise: hotel_cruise.id,
            guideInVisits: guide.id,
          });
        });
    }
    if (navigation) {
      navigate(`${navigation}${record?.key}`, {
        state: { isReservation: true },
      });
    } else {
      setEditingKey(record.key);
    }
  };

  const deleteRow = (index: number) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
  };

  const deleteRowAPI = async (id: any, key: any) => {
    if (title === "members")
      await deleteMemberData(id)
        .unwrap()
        .then(() => deleteRow(key))
        .catch((err: any) => console.log(err));
    if (title === "flights")
      await deleteFlightData(id)
        .unwrap()
        .then(() => deleteRow(key))
        .catch((err: any) => console.log(err));

    if (title === "accommodation") {
      await deleteAccommodation(id)
        .unwrap()
        .then(() => deleteRow(key))
        .catch((err: any) => console.log(err));
    }
    if (title === "pathway") {
      await deletePathway(id)
        .unwrap()
        .then(() => {
          deleteRow(key);
          window.location.reload();
        })
        .catch((err: any) => console.log(err));
    }
  };

  const cancel = (key: any) => {
    if (title === "pathway" && setIsLoading) {
      setEditingKey("");
      setIsLoading(true);
      window.location.reload();
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
      return;
    }
    if (clickedRowIndex === null && !isFromPathwayPage) {
      deleteRow(key);
    }
    setEditingKey("");
    setClickedRowIndex(null);
    form.resetFields();
    setIsAddedMember(true);
    setIsAddedFlight(true);
    setIsAddedAccommodation(true);
  };

  const save = async (record: any) => {
    try {
      const row = (await form.validateFields()) as any;

      const newData = [...data];
      const index = newData.findIndex((item) => record.key === item?.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
      }

      setData(newData);
      setEditingKey("");

      const formatTime = (time: Date) => {
        const hours = time.getHours().toString().padStart(2, "0");
        const minutes = time.getMinutes().toString().padStart(2, "0");
        const seconds = time.getSeconds().toString().padStart(2, "0");
        return `${hours}:${minutes}:${seconds}`;
      };

      const formatDate = (date: Date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      const value = newData.find((item) => record.key === item?.key);

      if (title === "members" && isAddedMember) {
        const newValueWithArrivalId = {
          ...value,
          arrival: urlId,
        };
        postMemberData(newValueWithArrivalId)
          .unwrap()
          .then(() => {
            window.location.reload();
          })
          .catch((err: any) => console.log(err));
      }
      if (!isAddedMember && title === "members") {
        updateMember({
          id: record.key,
          data: { ...value, nationality: value.nationality },
        })
          .unwrap()
          .then(() => {
            window.location.reload();
          })
          .catch((err: any) => console.log(err));
        setIsAddedMember(true);
      }

      if (isAddedFlight && title === "flights") {
        value.arrival_time = formatTime(new Date(value.arrival_time));
        value.departure_time = formatTime(new Date(value.departure_time));
        value.date = formatDate(new Date(value.date));
        value.departure = Number(value.flightFrom);
        value.destination = Number(value.flightTo);
        const newValueWithArrivalId = { ...value, arrival: urlId };
        postFlightData(newValueWithArrivalId)
          .unwrap()
          .then(() => {
            window.location.reload();
          })
          .catch((err: any) => console.log(err));
      }
      if (!isAddedFlight && title === "flights") {
        value.arrival_time = formatTime(new Date(value.arrival_time));
        value.departure_time = formatTime(new Date(value.departure_time));
        value.date = formatDate(new Date(value.date));
        value.departure = Number(value.flightFrom);
        value.destination = Number(value.flightTo);
        const newValueWithArrivalId = { ...value, arrival: urlId };
        updateFlight({
          id: record.key,
          data: newValueWithArrivalId,
        })
          .unwrap()
          .then(() => {
            window.location.reload();
          })
          .catch((err: any) => console.log(err));
        setIsAddedFlight(true);
      }
      if (title === "accommodation") {
        const checkOutDate = new Date(row.check_in);
        const Nights = JSON.parse(row?.night);
        checkOutDate.setDate(checkOutDate.getDate() + Nights);
        const formattedCheckOutDate = formatDate(checkOutDate);
        const accomodationData = {
          ...row,
          by: row.accByWho,
          category: row.category,
          member: row.accGuestName,
          city: row.accCity,
          type: row.accType,
          check_in: formatDate(new Date(row.check_in)),
          check_out: formattedCheckOutDate,
          due_date:
            row?.payment === "not_paid"
              ? formatDate(new Date(row.due_date))
              : null,
          hotel_cruise: row.hotelName,
          guide: row?.guideInVisits,
        };
        const newValueWithArrivalId = { ...accomodationData, arrival: urlId };
        if (!isAddedAccommodation && title === "accommodation") {
          updateAccommodation({
            id: record.id,
            data: newValueWithArrivalId,
          })
            .unwrap()
            .then(() => {
              window.location.reload();
            })
            .catch((err: any) => console.log(err));
          setIsAddedAccommodation(true);
        } else if (isAddedAccommodation && title === "accommodation") {
          createAccommodation(newValueWithArrivalId)
            .unwrap()
            .then(() => {
              window.location.reload();
            })
            .catch((err: any) => console.log(err));
        }
      }
      if (title === "pathway" && typeof record.id === "number") {
        setIsLoading?.(true);
        const nameValue =
          typeof value?.flight === "number"
            ? value?.flight
            : typeof value?.pathway === "number"
              ? value?.pathway
              : "";

        const pathwayData = {
          ...row,
          transportation: value.driverName,
          drop_off: value?.dropTo,
          flight: value?.flightNum || "",
          guide: value?.guideInVisits,
          leader: value.leaderName,
          name: nameValue,
          arrival: searchParams.get("id"),
          type: value?.type,
          date: formatDate(new Date(value.date)),
          adult: value.adultNum,
          child: value.childNum,
          pick_up: value?.pickUp,
          city: value?.city,
          time:
            value?.type === "flight" ? null : formatTime(new Date(value?.time)),
        };
        updatePathway({
          id: record.id,
          data: pathwayData,
        })
          .unwrap()
          .then(() => {
            fetchData();
            window.location.reload();
            if (setIsLoading) {
              setIsLoading(false);
            }
          })
          .catch((err: any) => {
            console.log(err);
          });
      }
      if (title === "pathway" && typeof record.id !== "number") {
        setIsLoading?.(true);
        postPathway({
          ...value,
          type: value.pathwayType,
          date: value?.date?.format("YYYY-MM-DD"),
          drop_off: value.dropTo,
          flight: value?.flightNum,
          adult: value.adultNum,
          child: value.childNum,
          leader: value.leaderName,
          name: value?.pathway || value?.flight,
          time: value?.time && formatTime(new Date(value?.time)),
          transportation: value?.driverName,
          arrival: searchParams.get("id"),
          guide: value?.guideInVisits,
          pick_up: value?.pickUp,
          file_num: value?.fileNum,
          trip_type: value?.trip_type,
        })
          .unwrap()
          .then(() => {
            fetchData();
            window.location.reload();
            if (setIsLoading) {
              setIsLoading(false);
              window.location.reload();
            }
          })
          .catch((err: any) => {
            const showErrorToast = (msg: string) => {
              toast.error(msg);
            };
            setIsLoading(false);
            showErrorToast("No Ticket Inventory!");
          });
      }
    } catch (errInfo) {
      if (setIsLoading) {
        setIsLoading(false);
      }
      console.log(errInfo);
      console.log("Validate Failed:", errInfo);
    }
  };

  const columnsWithAction: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    ...columns,
    {
      title: deleteActionText ? " " : "Action",
      dataIndex: "action",
      fixed: !["pathway", "Arrival_table"].includes(title) && "right",
      width: !["pathway", "Arrival_table"].includes(title) ? 100 : 70,
      render: (value, record, index) => {
        const editable = isEditing(record);

        return editable ? (
          <span style={{ display: "flex", alignItems: "center" }}>
            <Button
              btnClassName={`${styles.actionBtn}`}
              onClick={() => cancel(index)}
            >
              <Image src={CLOSE_IMG} alt="close-icon" />
            </Button>
            <Button
              btnClassName={`${styles.actionBtn}`}
              onClick={() => save(record)}
            >
              <Image src={CONFIRM_IMG} alt="confrim-icon" />
            </Button>
          </span>
        ) : title === "Arrival_table" ? (
          ""
        ) : (
          <ActionMenuButton
            disabled={editingKey !== ""}
            handleTourPath={tourPathMethod}
            handleEdit={() => {
              edit(record);
            }}
            onClick={() => {}}
            rowIndex={index}
            deleteRowIndex={
              title === "pathway" || title === "accommodation"
                ? record.id
                : record.key
            }
            setIndex={setClickedRowIndex}
            // handleDelete={deleteRow}
            deleteRowAPI={deleteRowAPI}
            isFromPathwayPage={isFromPathwayPage}
          />
        );
      },
    },
  ];

  const mergedColumns: any = columnsWithAction?.map((col: any) => {
    if (!col.editable) {
      if (col?.children?.length) {
        const editableChild = col?.children?.map((childCol: any) => {
          if (!childCol.editable) {
            return childCol;
          }
          return {
            ...childCol,
            onCell: (record: any) => ({
              record,
              inputType: getTableCellInputType(childCol.dataIndex),
              dataIndex: childCol.dataIndex,
              title: childCol.title,
              editing: isEditing(record),
              setSelectedPathway,
              setSelectedTrip,
            }),
          };
        });

        return {
          ...col,
          children: editableChild,
        };
      }

      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: getTableCellInputType(col.dataIndex),
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        setSelectedPathway,
        setSelectedTrip,
      }),
    };
  });

  const customRowStyle = (record: any, index: number) => {
    const result = [];
    if (index % 2) result.push("antdTableOddRowBg");
    if (index === clickedRowIndex) result.push("antdTableRowZIndex");
    if (title === "Arrival_table" && !record?.isHandled) {
      result.push("antdTableNewArrival");
    }

    return result.length ? result.join(" ") : "";
  };

  return (
    <Form form={form} component={false}>
      <Table
        style={{
          cursor: title === "Arrival_table" ? "pointer" : "",
        }}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        locale={{
          emptyText: " ",
        }}
        dataSource={data}
        columns={mergedColumns}
        rowClassName={customRowStyle}
        pagination={{
          onChange: (page) => {
            searchParams.set(paginationName || "", String(page));
            setSearchParams(searchParams);
          },
          total: paginationCount,
          pageSize: 5,
          showSizeChanger: false,
        }}
        loading={setIsLoading}
        onRow={(record, rowIndex) => ({
          onClick: () => {
            if (title === "Arrival_table") {
              edit(record);
            }
          },
        })}
        scroll={{ x: 1300 }}
        sticky={title === "pathway" ? undefined : { offsetHeader: 0 }}
      />
    </Form>
  );
}

import { useEffect, useState } from "react";
import DUMMY_DATA from "DATA";
import { setFiltered } from "reducers/appReducer";
import { useLocation, useSearchParams } from "react-router-dom";
import Text from "components/general/Text";
import BorderedWhiteBtn from "components/BorderedWhiteBtn";
import Image from "components/general/Image";
import { Calculation } from "apis/types/arrival";
import Icon from "components/general/Icon";
import WalletImg from "assets/images/empty-wallet.svg";
import { useDispatch, useSelector } from "react-redux";

import {
  useGetArrivalDataMutation,
  useGetCalculationMutation,
  useGetArrivalDataByIdMutation,
  useGetPathwayCalculationMutation,
} from "apis/services/arrival";
import { RootState } from "reducers";
import styles from "./styles.module.scss";

export default function MainLayoutInfo() {
  const token = localStorage.getItem("token");
  const { state, pathname } = useLocation();
  const [getCalculation] = useGetCalculationMutation();
  const [arrivalData] = useGetArrivalDataMutation();
  const [pathwayCalculationData] = useGetPathwayCalculationMutation({});

  const [calculationData, setCalculationData] = useState<Calculation>({
    new_arrival: 0,
    new_guests: 0,
    total_guest: 0,
  });
  const totalArrivalsCount = useSelector(
    (state: RootState) => state.app.arrivalsCount
  );
  const endDateFromRedux = useSelector((state: RootState) => state.app.endDate);
  const filtered = useSelector((state: RootState) => state.app.isFiltered);
  const [arrivalNumbers, setArrivalNumbers] = useState(0);
  const [arrival, setArrival] = useState<any>();
  const [totalCost, setTotalCost] = useState<any>();
  const [pathwayCalcData, setPathwayCalcData] = useState<any>();
  const [startDateUrl, setStartDateUrl] = useState<any>();
  const [endDateUrl, setEndDateUrl] = useState<any>();

  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [arrivalDatawithId] = useGetArrivalDataByIdMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      arrivalDatawithId(id)
        .unwrap()
        .then((value) => {
          setArrival(value.data);
          const tempObject: any = { EGP: 0, USD: 0 };

          value?.accommodation_cost?.forEach((cost: any) => {
            tempObject[cost.accommodation_arrival__currency__name] =
              cost.total_cost;
          });

          value?.guide_cost?.forEach((cost: any) => {
            tempObject[cost.traffic_arrival__guide__fee_currency__name] +=
              cost.total_guide_cost;
          });

          value?.leader_cost?.forEach((cost: any) => {
            tempObject[cost.traffic_arrival__leader__fee_currency__name] +=
              cost.total_leader_cost;
          });

          value?.traffic_name_cost?.forEach((cost: any) => {
            tempObject[cost.traffic_arrival__name__currency__name] +=
              cost.total_traffic_cost;
          });

          value?.vehicle_cost?.forEach((cost: any) => {
            tempObject[
              cost.traffic_arrival__transportation__vehicle_fees_currency__name
            ] += cost.vehicle_cost;
          });

          value?.visa_cost?.forEach((cost: any) => {
            tempObject[cost.traffic_arrival__visa_purchase_currency__name] +=
              cost.visa_price_cost;
          });

          const FinalCost = JSON.stringify(tempObject)
            .slice(1, -1)
            .replace(/"/g, "");

          setTotalCost(FinalCost);
        });
    }
  }, [id, endDateFromRedux]);

  useEffect(() => {
    if (searchParams.get("startDate") && searchParams.get("endDate")) {
      getCalculation({
        arrival_date_after: searchParams.get("startDate"),
        arrival_date_before: searchParams.get("endDate"),
      })
        .unwrap()
        .then((data) => {
          setCalculationData(data);
        })
        .catch((error) => {
          console.error("Error fetching calculation data:", error);
        });
      if (searchParams.get("startDate")) {
        arrivalData({
          arrival_date_after: searchParams.get("startDate"),
          arrival_date_before: searchParams.get("endDate"),
        })
          .unwrap()
          .then((arrivalNum) => setArrivalNumbers(arrivalNum.count));
      }
    } else if (token) {
      getCalculation({})
        .unwrap()
        .then((data) => setCalculationData(data));
      arrivalData({})
        .unwrap()
        .then((arrivalNum) => setArrivalNumbers(arrivalNum.count));
      pathwayCalculationData({})
        .unwrap()
        .then((value) => setPathwayCalcData(value));
    }
  }, [startDateUrl, endDateUrl, filtered, pathname]);

  const ReservationDetailsHeader = (
    <div className={`${styles.reservationContainer}`}>
      <div className={`${styles.textSide}`}>
        <div>
          <Text className={`${styles.title}`}>File Number</Text>
          <Text>{arrival?.file_number}</Text>
        </div>
        <div>
          <Text className={`${styles.title}`}>Agent File Num.</Text>
          <Text>{arrival?.agent_file_number}</Text>
        </div>
        <div>
          <Text className={`${styles.title}`}>Total Group</Text>
          <Text>
            {arrival?.number_of_adults} Members + {arrival?.number_of_kids}{" "}
            Child
          </Text>
        </div>
      </div>
      <div className={`${styles.priceSide}`}>
        <div className={`${styles.imgContainer}`}>
          <Image src={WalletImg} width={32} />
        </div>
        <Text>
          Total Cost: <span>{totalCost}</span>
        </Text>
      </div>
    </div>
  );

  if (state?.isReservation) return ReservationDetailsHeader;

  if (pathname === "/Dashboard/reservation-details")
    return ReservationDetailsHeader;

  if (state?.headerTitle === "Add New Arrival") return null;

  return (
    <div className={`${styles.infoCardContainer}`}>
      {pathname === "/Dashboard" &&
        DUMMY_DATA.home.map((ele) => (
          <BorderedWhiteBtn
            onClick={() => {
              if (ele.text === "New Arrival") {
                const today = new Date();
                const twoMonthsLater = new Date(
                  today.getFullYear(),
                  today.getMonth() + 2,
                  today.getDate()
                );
                const formattedStartDate = today.toISOString().split("T")[0];
                const formattedEndDate = twoMonthsLater
                  .toISOString()
                  ?.split("T")[0];
                searchParams.set("startDate", formattedStartDate);
                searchParams.set("endDate", formattedEndDate);
                searchParams.set("isNewArrival", "true");
                setSearchParams(searchParams);
              }
            }}
            key={ele?.id}
            containerStyle={`${styles.infoCard}`}
          >
            <div className={`${styles.innerInfoCard}`}>
              <div className={`${styles.textSide}`}>
                <p>{ele?.text}</p>
                {token && (
                  <span>
                    {ele.text === "New Arrival" && calculationData?.new_arrival}
                    {ele.text === "New Guests" && calculationData?.new_guests}
                    {ele.text === "Total Arrival" && totalArrivalsCount}
                    {ele.text === "Total Guests" &&
                      calculationData?.total_guest}
                  </span>
                )}
              </div>
              <div className={`${styles.iconContainer}`}>
                <Icon name={ele?.icon} size={24} color="Primary700" />
              </div>
            </div>
          </BorderedWhiteBtn>
        ))}
      {pathname === "/Dashboard/pathways" &&
        DUMMY_DATA.pathway.map((ele) => (
          <BorderedWhiteBtn
            onClick={() => {
              if (ele.text === "New Arrival") {
                const today = new Date();
                const twoMonthsLater = new Date(
                  today.getFullYear(),
                  today.getMonth() + 2,
                  today.getDate()
                );
                const formattedStartDate = today.toISOString().split("T")[0];
                const formattedEndDate = twoMonthsLater
                  .toISOString()
                  .split("T")[0];
                setStartDateUrl(formattedStartDate);
                setEndDateUrl(formattedEndDate);
                dispatch(setFiltered(true));
              }
            }}
            key={ele?.id}
            containerStyle={`${styles.infoCard}`}
          >
            <div className={`${styles.innerInfoCard}`}>
              <div className={`${styles.textSide}`}>
                <p>{ele?.text}</p>
                {token && (
                  <span>
                    {ele.text === "Pathway Today" &&
                      pathwayCalcData?.pathway_count}
                    {ele.text === "Flight Today" &&
                      pathwayCalcData?.flight_count}
                    {ele.text === "Merged Group" &&
                      pathwayCalcData?.merge_count}
                  </span>
                )}
              </div>
              <div className={`${styles.iconContainer}`}>
                <Icon name={ele?.icon} size={24} color="Primary700" />
              </div>
            </div>
          </BorderedWhiteBtn>
        ))}
    </div>
  );
}

import { useGetUserDataQuery } from "apis/services/auth";
import { useEffect, useState } from "react";
import Icon from "components/general/Icon";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Image from "components/general/Image";
import MenuImg from "assets/images/menu.svg";
import cancelImg from "assets/images/cancel.png";
import LOGOWITHTEXTIMG from "assets/images/logoWithText.png";
import { NavigationListItem } from "./types";
import MainLayoutInfo from "../MainLayoutInfo";
import MainLayoutHeader from "../MainLayoutHeader";
import styles from "./styles.module.scss";

//
interface IProps {
  iconName: string;
  title: string;
  isActive: boolean;
}

function SidebarButton({ iconName, title, isActive }: IProps) {
  return (
    <div className={styles.sidebarButtonContainer}>
      <Icon
        name={iconName}
        size={24}
        color={isActive ? "Primary700" : "IconGray"}
      />
      <span className={`${styles.open} ${isActive && styles.active}`}>
        {title}
      </span>
    </div>
  );
}

export default function SidebarLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { data: userData, isLoading, error } = useGetUserDataQuery();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  // const [userData, setUserData] = useState(null);

  useEffect(() => {
    const temp: any = {
      arrival_list: "/Dashboard",
      traffic: "/Dashboard/pathways",
      reports: "/Dashboard/reports",
      accommodation: "/Dashboard/accommodation",
    };

    const currentPath = window.location.pathname;
    const currentKey = Object.keys(temp).find(
      (key) => temp[key] === currentPath
    );
    console.log(
      "Current path:",
      window.location.pathname,
      currentKey,
      userData
    );

    if (currentKey && (userData as any)?.pages?.includes(currentKey)) {
      console.log("User has access to this page");
    } else {
      console.log("User does not have access to this page");

      if ((userData as any)?.pages?.[0]) {
        window.location.href =
          temp[(userData as any)?.pages?.[0] as keyof typeof temp];
      }
    }

    // console.log(
    //   userData,
    //   "userData",
    //   (userData as any)?.pages?.[0],
    //   temp[(userData as any)?.pages?.[0] as keyof typeof temp]
    // );
  }, [userData]);

  const handleSidebarButtonClick = () => {
    setTimeout(() => {
      toggleSidebar();
    }, 100);
  };

  const navigationList: NavigationListItem[] = [
    {
      title: "Dashboard",
      iconName: "home",
      href: "/Dashboard",
      haveAccess: (userData as any)?.pages?.includes("arrival_list"),
    },

    {
      title: "Traffic",
      iconName: "pathway",
      href: "/Dashboard/pathways",
      state: {
        isPathway: true,
      },
      haveAccess: (userData as any)?.pages?.includes("traffic"),
    },
    // {
    //   title: "Services",
    //   iconName: "services",
    //   href: "",
    // },
    {
      title: "Reports",
      iconName: "reports",
      href: "/Dashboard/reports",
      state: {
        hideInfo: true,
      },
      haveAccess: (userData as any)?.pages?.includes("reports"),
    },
    {
      title: "Accommodation",
      iconName: "Business",
      href: "/Dashboard/accommodation",
      haveAccess: (userData as any)?.pages?.includes("accommodation"),
    },

    // {
    //   title: "Users Activity",
    //   iconName: "user-activity",
    //   href: "",
    // },
    // {
    //   title: "Settings",
    //   iconName: "settings",
    //   href: "",
    // },
  ];

  const pathToPageMap: Record<string, string> = {
    arrival_list: "/Dashboard",
    traffic: "/Dashboard/pathways",
    reports: "/Dashboard/reports",
    accommodation: "/Dashboard/accommodation",
  };

  // Redirect logic
  useEffect(() => {
    if (!userData) return;

    const accessUserPages = (userData as any)?.pages || [];
    const currentPath = window.location.pathname;

    // Find the page key for the current path
    const currentPageKey = Object.keys(pathToPageMap).find(
      (key) => pathToPageMap[key] === currentPath
    );

    // Check if the current path is accessible
    const isCurrentPathAccessible = accessUserPages.includes(currentPageKey);

    // If the user doesn't have access to the current page, redirect them
    if (currentPageKey && !isCurrentPathAccessible) {
      const redirectPath = pathToPageMap[accessUserPages[0]];

      if (redirectPath) {
        navigate(redirectPath);
      }
    }
  }, [userData, navigate]);

  return (
    <div className="h-100">
      <div className={styles.toggleButton}>
        <button className={styles.sideBarButton} onClick={toggleSidebar}>
          <Image src={MenuImg} alt="Menu Image" />
        </button>
      </div>
      <aside
        className={`${styles.sidenav} ${styles.open} ${
          isSidebarOpen && styles.visible
        }`}
      >
        {isSidebarOpen && (
          <button
            className={styles.CancelSideBarButton}
            onClick={toggleSidebar}
          >
            <Image src={cancelImg} alt="cancel Image" />
          </button>
        )}
        <Image
          src={LOGOWITHTEXTIMG}
          alt="logo-rotana"
          height={56}
          width={186}
          style={{
            marginBottom: "43px",
          }}
        />

        <div className={`w-100 ${styles.navigationContent}`}>
          {navigationList?.map(
            (item) =>
              item.haveAccess && (
                <NavLink
                  key={item?.title}
                  to={item?.href}
                  state={item?.state}
                  className={({ isActive }) =>
                    isActive && !!item.href ? styles.active : ""
                  }
                  end
                  onClick={handleSidebarButtonClick}
                >
                  {({ isActive }) => (
                    <SidebarButton
                      iconName={item?.iconName}
                      title={item?.title}
                      isActive={isActive && !!item.href}
                    />
                  )}
                </NavLink>
              )
          )}
          <div>
            <NavLink
              to="/"
              onClick={() => {
                localStorage.clear();
                // window.location.reload();
              }}
            >
              <SidebarButton
                iconName="ArrowLeft"
                title="LogOut"
                isActive={false}
              />
            </NavLink>
          </div>
        </div>
      </aside>

      <main className={styles.sidelayoutContent}>
        <MainLayoutHeader />
        {!state?.hideInfo ? <MainLayoutInfo /> : ""}
        {children}
      </main>
    </div>
  );
}

/* eslint-disable react/jsx-no-constructed-context-values */
import {
  useGetAccommodationDataQuery,
  useGetTrafficNameQuery,
  useGetUserQuery,
  useGetVehicleQuery,
  useRetrieveFilghtDataQuery,
} from "apis/services/arrival";
import React, { ReactNode, createContext, useEffect, useState } from "react";

interface MyContextType {
  data: any;
  setData: React.Dispatch<React.SetStateAction<any>>;
}

// Create a new context
export const ReservationDetailsContext = createContext<
  MyContextType | undefined
>(undefined);

export default function ReservationDetailsContextProvider({
  arrivalId,
  children,
}: {
  arrivalId: any;
  children: ReactNode;
}) {
  const [data, setData] = useState<any>({});

  const [page, setPage] = useState(1);
  const { data: accommodationData, isLoading: isLoadingAccommodation } =
    useGetAccommodationDataQuery(
      {
        page,
        arrival: arrivalId,
      },
      { skip: !arrivalId }
    );

  // get all data from api
  useEffect(() => {
    if (accommodationData) {
      if (page === 1) {
        setData((prev: any) => ({
          ...prev,
          accommodation: accommodationData.results,
        }));
      } else {
        setData((prev: { accommodation: any }) => ({
          ...prev,
          accommodation: [...prev.accommodation, ...accommodationData.results],
        }));
      }

      if (accommodationData.next) {
        setPage((prevPage) => prevPage + 1);
      }
    }
  }, [accommodationData]);

  const [flightPage, setFlightPage] = useState(1);
  const { data: flightData, isLoading: isLoadingFlight } =
    useRetrieveFilghtDataQuery(
      {
        page: flightPage,
        arrival: arrivalId,
      },
      { skip: !arrivalId }
    );

  // get all data from api
  useEffect(() => {
    if (flightData?.next) {
      setFlightPage(flightPage + 1);
    }
    if (flightData?.pages_number === flightPage) {
      setData((prev: any) => ({
        ...prev,
        flight: flightData?.results,
      }));
    }
  }, [flightData?.next, isLoadingFlight]);

  const [trafficNamePage, setTrafficNamePage] = useState(1);
  const { data: trafficNameData, isLoading: isTrafficNameLoading } =
    useGetTrafficNameQuery({
      page: trafficNamePage,
      arrival: arrivalId,
    });

  // get all data from api
  useEffect(() => {
    if (trafficNameData?.next) {
      setTrafficNamePage(trafficNamePage + 1);
    }
    if (trafficNameData?.pages_number === trafficNamePage) {
      setData((prev: any) => ({
        ...prev,
        trafficName: trafficNameData?.results,
      }));
    }
  }, [trafficNameData?.next, isTrafficNameLoading]);

  // Get All Leaders
  const [userRoleLeaderPage, setUserRoleLeaderPage] = useState(1);
  const { data: userRoleLeaderData, isLoading: isUserRoleLeaderLoading } =
    useGetUserQuery({
      page: userRoleLeaderPage,
      role__name: "Leader",
    });

  // get all data from api
  useEffect(() => {
    if (userRoleLeaderData) {
      if (userRoleLeaderPage === 1) {
        setData((prev: any) => ({
          ...prev,
          userRoleLeader: userRoleLeaderData.results,
        }));
      } else {
        setData((prev: { userRoleLeader: any }) => ({
          ...prev,
          userRoleLeader: [
            ...prev.userRoleLeader,
            ...userRoleLeaderData.results,
          ],
        }));
      }

      if (userRoleLeaderData.next) {
        setUserRoleLeaderPage((prevPage) => prevPage + 1);
      }
    }
  }, [userRoleLeaderData]);

  // Get All Guides
  const [userRoleGuidePage, setUserRoleGuidePage] = useState(1);
  const { data: userRoleGuideData, isLoading: isUserRoleGuideLoading } =
    useGetUserQuery({
      page: userRoleGuidePage,
      role__name: "Guide",
    });

  // get all data from api
  useEffect(() => {
    if (userRoleGuideData) {
      setData((prev: any) => {
        const existingIds = prev.userRoleGuide
          ? prev.userRoleGuide.map((item: any) => item.id)
          : [];
        const newResults = userRoleGuideData.results.filter(
          (item: any) => !existingIds.includes(item.id)
        );

        return {
          ...prev,
          userRoleGuide: [
            ...(prev.userRoleGuide || []),
            ...newResults.map((item: any) => ({
              ...item,
              key: item.id,
            })),
          ],
        };
      });

      if (userRoleGuideData.next) {
        setUserRoleGuidePage((prevPage) => prevPage + 1);
      }
    }
  }, [userRoleGuideData]);

  return (
    <ReservationDetailsContext.Provider value={{ data, setData }}>
      {children}
    </ReservationDetailsContext.Provider>
  );
}
